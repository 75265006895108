<template>
  <div class="detail-content">
    <div class="detail">
      <div class="content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.detail-content {
  width: 100%;
  .detail {
    max-width: 1200px;
    min-width: 1200px;
    height: 100%;
    margin: 0 auto;

    .content {
      width: 100%;
    }
  }
}
</style>
